import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Jeg er <span className="purple">Henning Osmo Nordhagen </span>
            fra <span className="purple"> Bærum, Oslo.</span>
            <br />
            For tiden er jeg informatikkstudent på IFI, ved Universitetet i Oslo.
            Jeg går 2. året, med forydpning i programmering og systemarkitektur.
            Jobber deltid som gruppelærer i "Introduksjon til datateknologi" (IN1020), hvor jeg lærer førsteårsstudenter enkle konsepter innenfor datateknologi og programmering!
            <br />
            <br />
            Når jeg ikke studerer, har jeg andre ting jeg liker å gjøre!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Lese en god bok
            </li>
            <li className="about-activity">
              <ImPointRight /> Stå på ski
            </li>
            <li className="about-activity">
              <ImPointRight /> Jakt og fiske
            </li>
          </ul>
{/* 
          <p style={{ color: "rgb(71 155 228)" }}>
            "Sånn man gjør noe er sånn man gjør alt"{" "}
          </p>
          <footer className="purple"> - Henning</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
